@use 'sass:math';
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './palettes.scss';

$margin-top: 36px;
$margin-left: 16px;
$margin-right: 16px;
$margin-bottom: 36px;

$item-margin-bottom: 24px;

$color-primary: mat-color($md-primary, 500);
$color-accent: mat-color($md-white, 500);

$body-background-color: mat-color($md-primary, 500);
$card-background-color: mat-color($md-white, 500);

$text-color-primary: mat-color($md-primary, 500);
$text-color-secondary: mat-color($md-white, 500);
$text-color-black: mat-color($md-black, 500);
$text-color-gray: mat-color($md-gray, 900);

$font-family: 'Roboto', sans-serif;
$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-big: 20px;

$icon-color-primary: mat-color($md-primary, 500);
$icon-color-secondary: mat-color($md-white, 500);

$hover-white-button-color: mat-color($md-gray, 500);
$hover-blue-button-color: mat-color($md-primary, 400);
$button-border-color: mat-color($md-primary, 500);

$red-color: mat-color($md-red, 500);
$orange-color: mat-color($md-orange, 500);
$yellow-color: mat-color($md-yellow, 500);
$green-color: mat-color($md-green, 500);

$breakpoints: (
  'mobile': (
    min-width: 320px,
  ),
  'tablets': (
    min-width: 767px,
  ),
  'desktop': (
    min-width: 991px,
  ),
);

@mixin respond-to($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  } @else {
    @error '@mixin respond-to: `#{$name}` is not a key in breakpoints.' +
    ' Available breakpoints are: `#{$breakpoints}`';
  }
}

$rem-base: 16px !default;

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return math.div($num, ($num * 0 + 1));
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: math.div(strip-unit($value), strip-unit($base-value)) * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append(
      $remValues,
      convert-to-rem(nth($values, $i), $base-value)
    );
  }
  @return $remValues;
}
