@import './app/core/themes/light-theme.scss';
@import './app/core/themes/sizes.scss';

.bold {
  font-weight: bold !important;
}

.pointer {
  cursor: pointer;
}
