@use 'sass:map';
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'variables';
@import 'toast';
@import 'fonts';

$my-custom-typography: mat.define-typography-config(
  $title: mat.define-typography-level(20px, 20px, 700),
);

@include mat.core($my-custom-typography);

$ymunity-primary: mat.define-palette($md-primary);
$ymunity-accent: mat.define-palette($md-accent);
$ymunity-warn: mat.define-palette($md-red);

$ymunity-theme: mat.define-light-theme(
  (
    color: (
      primary: $ymunity-primary,
      accent: $ymunity-accent,
      warn: $ymunity-warn,
    ),
  )
);

$material-theme-overrides: (
  'color': (
    'foreground': (
      'divider': mat-color($md-primary, 600),
      'text': $text-color-gray,
    ),
  ),
);

$ymunity-theme: map.deep-merge($ymunity-theme, $material-theme-overrides);

@include mat.all-component-typographies($my-custom-typography);
@include mat.core-theme($ymunity-theme);
@include mat.all-component-themes($ymunity-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background-color: #e5e5e5;
}

* {
  box-sizing: border-box;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.snack-success {
  background: mat-color($md-green, 800);
  color: mat-color($md-white, 500);
}

.snack-success .mat-button-wrapper {
  color: mat-color($md-white, 500);
}

.snack-warn {
  background: mat-color($md-red, 900);
  color: mat-color($md-white, 500);
}

.snack-warn .mat-button-wrapper {
  color: mat-color($md-white, 500);
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-width: 2px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-width: 2px !important;
}

.form-field {
  margin-bottom: 10px;
}

.mat-tab-header {
  border-bottom: none;
}

.mat-stroked-button, .mat-stroked-button:not(.mat-button-disabled) {
  color: $text-color-gray;
  border-color: mat-color($md-gray, 400);
}

.mat-accent {
  &.mat-tab-group {
    .mat-tab-label-active {
      .mat-tab-label-content {
        color: mat-color($md-accent, 500);
      }
    }
  }
}

.asterix:after {
  content: '*';
  color: $red-color;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
label {
  color: $text-color-gray;
}

thead {
  font-size: $font-size-normal;
}
tbody {
  font-size: $font-size-small;
}

.mat-radio-label-content {
  font-size: $font-size-normal;
  color: mat-color($md-accent, 500);
}

// TODO: Remover esse escopo quando incluir o novo componente de radio button na tela de elegibilidade
.mat-radio-button {
  &.mat-accent {
    .mat-radio-inner-circle {
      background-color: mat-color($md-accent, 500);
    }
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: mat-color($md-accent, 500);
      }
    }
  }
}

button {
  letter-spacing: 2px;
}
