@import 'variables';

.snackBar {
  color: mat-color($md-black, 500);
  background-color: $color-accent;

  & > div {
    width: 100%;
  }

  &.success {
    border-color: $green-color;
    background-color: $green-color;

    .mat-icon.mr-xs {
      color: mat-color($md-white, 500);
    }
  }

  &.error {
    border-color: $red-color;
    background-color: $red-color;

    .mat-icon.mr-xs {
      color: mat-color($md-white, 500);
    }
  }
}
